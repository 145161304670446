<template>
  <div class="projects-container">
    <div class="container-item card">
      <div class="card-header">
        <h3>Ország, város, fiú, lány</h3>
      </div>
      <div class="card-body">
        <img src="@/assets/img/mockups/orszag_varos_fiu_lany_product_mockup.png" alt="Ország, város, fiú, lány" />
        <div>
          <h4>Leírás:</h4>
          <p>
            Évekkel ezelőtt akárhányszor szerettünk volna "Ország, város, fiú, lány" játékot játszani, mindig azzal
            szembesültünk, hogy akaratlanul a már korábban elhangzott betűket
            mondjuk, illetve folyamatosan elfelejtettünk stoppert indítani, így amikor elkezdtem ismerkedni a
            Flutterrel, adódott az ötlet, hogy először ezt valósítsam meg. A fejlesztés után kiderült, hogy hiába
            nagyon egyszerű az megírt alkalmazás, másnak is hasznos lenne, így a Google Play Áruházban is elérhetővé
            tettem.
          </p>
          <h4>Felhasznált technológiák:</h4>
          <ul>
            <li>Flutter</li>
            <ul>
              <li>Shared preferences</li>
              <li>Riverpod</li>
              <li>Flutter localizations</li>
            </ul>
          </ul>
        </div>
        <button
          v-on:click="openLink('https://play.google.com/store/apps/details?id=hu.duplaem.orszag_varos_fiu_lany')"><font-awesome-icon
            :icon="['fas', 'globe']" class="button-icon" />Google Play</button>
      </div>
    </div>
    <div class="container-item card">
      <div class="card-header">
        <h3>duplaem.hu</h3>
      </div>
      <div class="card-body">
        <img src="@/assets/img/mockups/duplaem_product_mockup.png" alt="duplaem.hu" />
        <div>
          <h4>Leírás:</h4>
          <p>
            Eredetileg egy Vue tanulós hobbiprojektnek indult, ami Firebase integrációval egy egyszerű CMS rendszer
            lett volna. Illetve volt is, de 1 évvel később, amikor úgy döntöttem, hogy eleget fizettem feleslegesen a
            domainért azért, hogy egy "hamarosan..." feliratra mutasson, kihúztam minden felesleges elemet és
            portfólió oldallá
            avanzsált.
          </p>
          <h4>Felhasznált technológiák:</h4>
          <ul>
            <li>Vue 3</li>
            <li>SCSS</li>
            <li>Firebase (csak hosting)</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="container-item card">
      <div class="card-header">
        <h3>Szalai Favágás Komárom</h3>
      </div>
      <div class="card-body">
        <img src="@/assets/img/mockups/favagas_komarom_product_mockup.png" alt="Szalai Favágás Komárom" />
        <div>
          <h4>Leírás:</h4>
          <p>
            A Szalai Favágás Komárom csapatának készült egyszerű bemutatkozó
            oldal.
          </p>
          <h4>Felhasznált technológiák:</h4>
          <ul>
            <li>Vue 3</li>
            <li>Tailwind CSS</li>
            <li>Firebase (csak hosting)</li>
          </ul>
        </div>
        <button v-on:click="openLink('https://favagaskomarom.hu')"><font-awesome-icon :icon="['fas', 'globe']"
            class="button-icon" />favagaskomarom.hu</button>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.projects-container {
  .card-body {
    display: grid;
    gap: 1.25rem;

    img {
      width: 100%;
      filter: drop-shadow(0 0.5rem 0.125rem rgba(0, 0, 0, 0.125));
    }

    li {
      margin-left: 1rem;
    }
  }
}

@media screen and (max-width: 992px) {
  .projects-container {
    .card:not(:last-of-type) {
      margin-bottom: 2.5rem;
    }
  }
}

@media screen and (min-width: 992px) {
  .projects-container {
    .card {
      margin-bottom: 1.25rem;

      .card-body {
        display: grid;
        grid-template-columns: minmax(20rem, 1fr) 3fr;
        align-items: center;
      }
    }
  }
}
</style>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  mounted() {
    window.scrollTo(0, 0);
  },
  methods: {
    openLink(link: any): void {
      window.open(link, '_blank')
    }
  }
});
</script>