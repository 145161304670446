import { createApp } from "vue";
import App from "@/App.vue";
import router from "@/router";

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faAnglesRight, faBars, faCopyright, faEnvelope, faHandshake, faInfoCircle, faPersonHiking, faScrewdriverWrench, faXmark, faGlobe } from '@fortawesome/free-solid-svg-icons';
import { faGithub, faLinkedin } from '@fortawesome/free-brands-svg-icons';

import NavComponent from "@/components/NavComponent.vue";
import MenuComponent from "@/components/MenuComponent.vue";
import ProgressBarComponent from "@/components/ProgressBarComponent.vue";

const app = createApp(App);

library.add(faBars);
library.add(faPersonHiking);
library.add(faScrewdriverWrench);
library.add(faEnvelope);
library.add(faXmark);
library.add(faAnglesRight);
library.add(faHandshake);
library.add(faLinkedin);
library.add(faGithub);
library.add(faInfoCircle);
library.add(faGlobe);
library.add(faCopyright);

app.use(router);

app.component('font-awesome-icon', FontAwesomeIcon);

app.component('NavComponent', NavComponent);
app.component('MenuComponent', MenuComponent);
app.component('ProgressBarComponent', ProgressBarComponent);

app.mount("#app");