<template>
  <nav>
    <router-link to="/" id="logo">
      Dupla<span>e</span>m<span>.hu</span>
    </router-link>
    <div class="nav-items">
      <router-link v-for="route in routes" v-bind:key="route.path" v-bind:to="route.path"
        v-slot="{ isActive, isExactActive }">
        <span><font-awesome-icon :icon="['fas', 'angles-right']" v-if="isActive || isExactActive" /> {{ route.name
          }}</span>
      </router-link>
    </div>
    <font-awesome-icon :icon="['fas', 'bars']" v-on:click="$emit('changeMenuVisibility')" class="menu-link" />
  </nav>
</template>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";

nav {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  height: $navbar-height;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: box-shadow 0.25s ease-in-out;

  >* {
    margin: 0 1.25rem;
  }
}

#logo {
  font-weight: bold;
  color: $text-color;
  text-transform: uppercase;
  text-shadow: 0 0 0.5rem $text-color;
}

@keyframes glowing {

  0%,
  70%,
  75%,
  80%,
  82% {
    opacity: 1;
  }

  71%,
  74%,
  81% {
    opacity: 0.25;
  }
}

@media screen and (orientation: portrait),
(max-width: 992px) {
  nav {
    background-color: $dark-color;
    z-index: 99;
  }

  .nav-items {
    display: none;
  }

  .menu-link {
    height: 1.5rem;
    color: $text-color;
  }
}

@media screen and (min-width: 992px) {
  nav {
    background-color: $main-color;
    z-index: 97;
  }

  #logo {
    span {
      animation: glowing 10s ease-in-out 0s infinite normal both;
    }
  }

  .menu-link {
    display: none;
  }

  .nav-items {
    display: flex;
    gap: 1.25rem;
  }
}
</style>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  data() {
    return { routes: this.$router.options.routes };
  },
});
</script>