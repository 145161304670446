import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import AboutMeView from '@/views/AboutMeView.vue';
import ProjectsView from '@/views/ProjectsView.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/about-me',
  },
  {
    path: "/about-me",
    name: "Bemutatkozás",
    component: AboutMeView
  },
  {
    path: "/projects",
    name: "Projektek",
    component: ProjectsView
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;