import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_nav_component = _resolveComponent("nav-component")!
  const _component_menu_component = _resolveComponent("menu-component")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_nav_component, {
      class: _normalizeClass({ 'add-box-shadow': _ctx.isScrolled && !_ctx.isMenuVisible }),
      onChangeMenuVisibility: _ctx.changeMenuVisibility
    }, null, 8, ["class", "onChangeMenuVisibility"]),
    _createVNode(_Transition, null, {
      default: _withCtx(() => [
        (_ctx.isMenuVisible)
          ? (_openBlock(), _createBlock(_component_menu_component, {
              key: 0,
              onChangeMenuVisibility: _cache[0] || (_cache[0] = ($event: any) => (_ctx.changeMenuVisibility()))
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_1, [
      (_ctx.isMenuVisible)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "disable-container",
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.changeMenuVisibility && _ctx.changeMenuVisibility(...args)))
          }))
        : _createCommentVNode("", true),
      _createElementVNode("div", {
        class: _normalizeClass(["content-container", { 'decrease-opacity': _ctx.isMenuVisible }])
      }, [
        _createElementVNode("main", null, [
          _createVNode(_component_router_view, null, {
            default: _withCtx(({ Component }) => [
              _createVNode(_Transition, {
                name: "fade",
                mode: "out-in"
              }, {
                default: _withCtx(() => [
                  (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
                ]),
                _: 2
              }, 1024)
            ]),
            _: 1
          })
        ]),
        _createElementVNode("footer", null, [
          _createElementVNode("span", null, [
            _createTextVNode(" duplaem.hu "),
            _createVNode(_component_font_awesome_icon, { icon: ['fas', 'copyright'] }),
            _createTextVNode(" 2024 ")
          ])
        ])
      ], 2)
    ])
  ], 64))
}