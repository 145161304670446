<template>
  <div class="menu">
    <ul>
      <li v-for="route in routes" v-bind:key="route.name">
        <router-link v-bind:key="route.path" v-bind:to="route.path" v-slot="{ isActive, isExactActive }"
          v-on:click="$emit('changeMenuVisibility')">
          <span><font-awesome-icon :icon="['fas', 'angles-right']" v-if="isActive || isExactActive" /> {{ route.name
            }}</span>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";

.menu {
  background-color: $dark-color;
  box-shadow: $my-box-shadow;

  ul {
    margin: 0;
  }

  li {
    margin: 0 0 0.5rem 0;
    list-style: none;
  }

  @for $i from 1 through 3 {
    li:nth-of-type(#{$i}) {
      animation: slide 0.25s ease-in-out $i * 0.25s 1 backwards;
    }

    @keyframes slide {
      0% {
        opacity: 0;
        transform: translateX(3rem);
      }

      100% {
        opacity: 1;
        transform: none;
      }
    }
  }
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.v-enter-active,
.v-leave-active {
  transition: all 0.25s ease-in-out;
}

.v-enter-to,
.v-leave-from {
  opacity: 1;
}

@media screen and (max-width: 992px) {
  .menu {
    position: fixed;
    left: 0;
    top: 4rem;
    width: 100%;
    padding: 1.25rem;
    z-index: 98;
  }
}

@media screen and (min-width: 992px) {
  .menu {
    display: none;
  }
}
</style>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  data() {
    return { routes: this.$router.options.routes };
  },
  computed: {
    getRoutesLength(): number {
      return this.routes.length;
    }
  }
});
</script>