import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-30374288"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "menu" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("ul", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.routes, (route) => {
        return (_openBlock(), _createElementBlock("li", {
          key: route.name
        }, [
          (_openBlock(), _createBlock(_component_router_link, {
            key: route.path,
            to: route.path,
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('changeMenuVisibility')))
          }, {
            default: _withCtx(({ isActive, isExactActive }) => [
              _createElementVNode("span", null, [
                (isActive || isExactActive)
                  ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                      key: 0,
                      icon: ['fas', 'angles-right']
                    }))
                  : _createCommentVNode("", true),
                _createTextVNode(" " + _toDisplayString(route.name), 1)
              ])
            ]),
            _: 2
          }, 1032, ["to"]))
        ]))
      }), 128))
    ])
  ]))
}